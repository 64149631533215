import isObject from 'lodash.isobject';
import { price_amendment_range } from './products';

//wraps setProperty
export function setPropertyWrapper(state, newState) {
    for (let fieldName in newState) {
        setProperty(state, fieldName, newState[fieldName]);
    }
}

export function getProperty(object, property, create) {
    if (!object || !property) return undefined;
    return nestedGet(object, property.split('.'), create);
}

//note: set may not work if the end property is an array
//example. This should work:
//  some[0].property = value | works
//  some.properties[0] = value | may not work
export function setProperty(object, property, value) {
    if (!object || !property) return undefined;
    const properties = property.split('.');
    const finalProperty = properties.pop();
    const result = nestedGet(object, properties, true);
    return result && finalProperty ? (result[finalProperty] = value) : undefined;
}

const squareBracketPattern = /\[+(.*?)\]+/g;

function nestedGet(object, properties, create) {
    object = object || window;

    for (let i = 0, property; object && (property = properties[i]); i++) {
        let match = property.match(squareBracketPattern);
        if (match && match.length === 1 && match[0].length > 0) {
            const index = match[0].substring(1, match[0].length - 1);
            property = property.replace('[' + index + ']', '');
            const array = object[property];
            if (array) object = array ? object[property][index] : create ? (object[property] = {}) : undefined;
            else object = create ? [] : undefined;
        } else {
            object = property in object ? object[property] : create ? (object[property] = {}) : undefined;
        }
    }

    return object;
}

/**
 * detects whether the page has loaded with form data for the first time
 * useful if you want to set obj state properties after the form loads
 */
export function isFirstLoad(obj, lastProps) {
    const oldForm = lastProps.form;
    const newForm = obj.props.form;
    return newForm && oldForm && oldForm.loading && !newForm.loading;
}

/**
 * retrieves an array that uses edges. returns an empty edge array if not present
 */
export const getArray = (context, arrayName) => {
    const { form } = context.props;
    if (!form) return [];
    return getProperty(form.fields, arrayName) || [];
};

/**
 * retrieves an array that uses edges. returns an empty edge array if not present
 */
export const getObjectFromArray = (context, arrayName, index = 0) => {
    const array = getArray(context, arrayName);
    if (array.length > 0 && index < array.length) {
        return array[index];
    }
    return null;
};

/**
 * groups a collection by a property into a map
 */
export const groupBy = (list, getKeyFunc, getValueFunc, asArray = false) => {
    const map = new Map();
    for (let x = 0; x < list.length; x++) {
        const key = getKeyFunc(list[x]);
        const collection = map.get(key);
        const value = getValueFunc ? getValueFunc(list[x], x) : list[x];
        if (!collection) {
            map.set(key, [value]);
        } else {
            collection.push(value);
        }
    }

    if (!asArray) return map;

    const array = [];
    for (let [key, value] of map) array.push({ key, value });

    return array;
};

/**
 * groups a collection by a property into a map
 */
export const isNullOrUndefined = obj => {
    return obj === undefined || obj === null;
};

/**
 * Apollo includes the `__typename` field on the data returned from queries
 * We recursively iterate over the given data to remove all `__typename` fields
 * so that the object is sanitized before before saving it to GQL
 *
 * Also destroy ghost objects with ID = 0, go Pacman!
 *
 * @param {*} input
 */
export const deleteTypeName = input => {
    Object.keys(input).forEach(key => {
        if (key === '__typename') {
            try {
                delete input[key];
            } catch (e) {
                console.warn(e);
            }
        } else if (isObject(input[key])) {
            if (input[key].ID && (input[key].ID === 0 || input[key].ID === '0')) {
                delete input[key];
            } else deleteTypeName(input[key]);
        }
    });
    return input;
};

export const flattenKeys = (obj, ns, hash) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        let keypath = ns ? `${ns}.${key}` : key;
        if (typeof value === 'object' && value !== null) {
            return flattenKeys(value, keypath, acc);
        }
        acc[keypath] = value;
        return acc;
    }, hash || {});
};

export const resetAllProperties = obj => {
    clearNestedValues(obj);
};

const clearValue = obj => {
    if (obj === null || obj === undefined) return null;

    var type = typeof obj;

    if (type === 'boolean') return false;

    if (type === 'string') return null;

    if (type === 'number') return 0;

    clearNestedValues(obj);

    return obj;
};

const clearNestedValues = obj => {
    if (typeof obj !== 'object') return;
    Object.keys(obj).forEach(key => (obj[key] = clearValue(obj[key])));
};

export const round = (value, decimals = 2) => {
    var factor = Math.pow(10, decimals);
    var result = Math.round(value * factor) / factor;
    return result;
};

/**
 * limits a value to a range
 * @param {Number} value The test value (eg. 10)
 * @param {Number} base The base value (eg. 50)
 * @param {Number} range The min & max range beyond the base value (eg. 100)
 * @param {Boolean} allowNegative Permit the range to go negative, or stop at zero (eg. false)
 *
 * As per above, entering 10 will be limited between 0 to 150.
 */
export const limitRange = (value, base, range, allowNegative = false) => {
    const baseNumber = parseFloat(base);
    const valueNumber = parseFloat(value);

    if (isNaN(valueNumber) || isNaN(baseNumber)) return valueNumber;

    const priceRange = isNaN(range) ? price_amendment_range : range;

    const max = baseNumber + priceRange;
    if (valueNumber > max) return max;

    const min = allowNegative ? baseNumber - priceRange : Math.max(0, baseNumber - priceRange);
    if (valueNumber < min) return min;

    return valueNumber;
};

export const orientationMode = {
    horizontal: 0,
    vertical: 1
};

export const updateNested = (original, update) => {
    if (update === null) return update;
    const update_type = typeof update;
    if (update_type === 'object') {
        if (Array.isArray(update)) {
            original = [];
            update.forEach(entry => {
                const entry_type = typeof entry;
                if (entry_type === 'object') {
                    original.push(updateNested(null, entry));
                } else {
                    original.push(entry);
                }
            });
        } else {
            if (isNullOrUndefined(original)) original = {};
            for (let property in update) {
                const property_type = typeof update[property];
                if (property_type === 'object') {
                    if (
                        !!update[property] &&
                        !!update[property]['definitions'] &&
                        !!update[property]['kind'] &&
                        !!update[property]['loc']
                    ) {
                        //We don't need to update fragments, just overwrite it with new one
                        original[property] = update[property];
                    }
                    original[property] = updateNested(original[property], update[property]);
                } else if (property === undefined) {
                    delete original[property];
                } else {
                    original[property] = update[property];
                }
            }
        }
    } else {
        return update;
    }
    return original;
};

/**
 * counts to a specific number
 * @param {*} number
 */
export const countTo = number => {
    const arr = [];
    for (let x = 0; x < number; x++) arr.push(x);
    return arr;
};
