import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '../../../component/form/TextField';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import { withStyles } from '@material-ui/core/styles';

class SpecialInstructionModal extends Component {
    static defaultProps = {
        title: 'Enter Special Instruction Details'
    };

    state = {
        ID: null,
        Comment: '',
        Check: false,
        Action: ''
    };

    componentWillReceiveProps(nextProps) {
        const next = nextProps.specialInstruction;
        if (next) this.setState({ ...next });
    }

    getId() {
        return `SpecialInstructionModal-${this.props.title}`;
    }

    onTextChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onCheckedChange = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    clearState() {
        this.setState({
            ID: null,
            Comment: '',
            Check: false,
            Action: ''
        });
    }

    onCancel() {
        const { onCancel } = this.props;

        if (onCancel) onCancel();

        this.clearState();
    }

    onSubmit = () => {
        const { onSubmit } = this.props;

        if (onSubmit)
            onSubmit({
                ...this.state
            });

        this.clearState();
    };

    render() {
        return (
            <Dialog open={this.props.open} aria-labelledby={this.getId()}>
                {this.renderForm()}
            </Dialog>
        );
    }

    renderForm = () => {
        const { title, disabled } = this.props;

        return (
            <div>
                <DialogTitle id={this.getId()}>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            {this.renderField('Action', 'Action', false, false, disabled)}
                        </Grid>

                        <Grid item xs={12}>
                            {this.renderField('Comment', 'Additional Comments')}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderCheckField('Check', 'Confirmed', false, false, disabled)}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onCancel()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </div>
        );
    };

    renderField(name, label, autoFocus, required, disabled) {
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={this.state[name] || ''}
                onChange={this.onTextChange}
                disabled={disabled}
            />
        );
    }

    renderCheckField(name, label, autoFocus, required, disabled) {
        const { classes } = this.props;
        const checked = this.state[name] || '';
        return (
            <Grid className={classes.alignWithFloatingLabel}>
                <Checkbox
                    label={label}
                    name={name}
                    autoFocus={autoFocus}
                    required={required}
                    checked={checked}
                    onChange={this.onCheckedChange}
                    disabled={disabled}
                />
            </Grid>
        );
    }
}

const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    }
});

export default withStyles(styles)(SpecialInstructionModal);
