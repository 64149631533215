import {
    Arrangers,
    AshesDetail,
    Cars,
    Clergy,
    CoffinBearings,
    Disposal,
    FuneralDressing,
    Grave,
    GraveLedger,
    LimoBookings,
    PlaceOfService,
    PlaceOfViewingItems,
    ReflectionRoom,
    ValuableItems
} from '../FuneralFragments';
import { buildTabDataFragment } from '../../../util/functions';
import gql from 'graphql-tag';

export default buildTabDataFragment(
    [
        gql`
            fragment DetailsFields on Funeral {
                WebcastConfirmed
                WebcastEmailedLinkToFamily
                WebcastComment
                WebcastRequired

                ClergyRequired
                # ClergyComment
                # ConfirmedClergy
                ClergyFeeNotRequired

                ReflectionRoomRequired
                LedgerRequired
                HearseRequired
                CarRequired
                LimoRequired

                HearseToPassHouse
                HearseToPassHouseDuration
                HearseToPassHouseDate
                HearseToPassHouseTime

                HearseToPassHouseAfterService
                HearseToPassHouseAfterServiceDate
                HearseToPassHouseAfterServiceDuration
                HearseToPassHouseAfterServiceTime

                HearseDriveAway
                HearseDriveAwayDate
                HearseDriveAwayTime
                HearseDriveAwayDuration

                FollowToDisposal

                HearseComment
                CoffinComment

                CremationOrder {
                    ID
                    CremationPending
                    CremationComplete
                }
            }
        `,
        AshesDetail,
        Cars,
        Clergy,
        CoffinBearings,
        Disposal,
        FuneralDressing,
        Grave,
        GraveLedger,
        LimoBookings,
        PlaceOfService,
        PlaceOfViewingItems,
        ReflectionRoom,
        ValuableItems,
        Arrangers
    ],
    'Funeral'
);
