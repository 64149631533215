import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../icon/CloseIcon';
import { isNullOrUndefined } from 'util';
import Grid from './Grid';
import { OutlineButton } from './PrimaryButton';

class PhotoModal extends Component {
    render() {
        const { photo, onClose, classes } = this.props;
        const open = !isNullOrUndefined(photo);

        return (
            <Dialog open={open} classes={{ paper: classes.root }}>
                <DialogTitle>
                    <div>
                        <strong>{photo && photo.Name}</strong>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid pc={1}>
                        <img src={photo && photo.AbsoluteLink} alt={photo && photo.Name} />
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <OutlineButton onClick={onClose}>
                        <CloseIcon />
                        Close
                    </OutlineButton>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = () => ({
    root: {
        maxWidth: 'unset'
    },
    actions: {
        padding: '0px 15px 15px 0px'
    }
});

export default withStyles(styles)(PhotoModal);
