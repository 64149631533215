import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';
import ColumnLayout from '../../../component/ColumnLayout';
import DocumentModal from '../../funeral/billing/documentModal/DocumentModal';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import LinkButton from '../../../component/form/LinkButton';
import FileUpload, { getFileName } from '../../../component/form/FileUpload';
import { withRouter } from 'react-router';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import TextField from '../../../component/form/TextField';
import HistoryField from '../../../component/form/HistoryField';

class Summary extends Component {
    state = {
        documentModal: { open: false, document: null }
    };

    viewRecord = (event, path) => {
        event.preventDefault();
        this.props.history.push(path);
    };

    render() {
        const { form } = this.props;
        const { documentModal } = this.state;
        return (
            <Fragment>
                <DocumentModal
                    onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                    open={!!documentModal.open}
                    form={form}
                    document={documentModal.document}
                />

                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        return (
            <Grid container spacing={16}>
                {this.renderLegacyDocuments()}
            </Grid>
        );
    }

    renderSecondColumn() {
        return (
            <Grid container spacing={24}>
                {this.renderEnquiries()}
                {this.renderNotes()}
            </Grid>
        );
    }

    renderEnquiries() {
        const { Enquiries, Quote } = this.props.form.fields;
        const hasEnquiry = !!Enquiries && Enquiries.length > 0;
        const enqPath = !!Quote && `/quotes/enquiry/${Quote.Enquiry.LegacyKey}/${Quote.Enquiry.ID}`;
        return (
            <Grid bucket>
                <Grid item xs={12}>
                    <Label>Related Enquiries</Label>
                </Grid>
                <Grid item xs={12}>
                    <ol>
                        {!!Quote && (
                            <li>
                                Quote #{Quote.ID}
                                {!!Quote.Enquiry && (
                                    <Fragment>
                                        {' '}
                                        from{' '}
                                        <a href={enqPath} onClick={e => this.viewRecord(e, enqPath)}>
                                            Enquiry {Quote.Enquiry.LegacyKey}
                                        </a>
                                    </Fragment>
                                )}
                            </li>
                        )}
                        {!!hasEnquiry &&
                            Enquiries.map((enq, i) => {
                                const enqPath = `/quotes/enquiry/${enq.LegacyKey}/${enq.ID}`;
                                const funPath = !!enq.Funeral && `/funeral/${enq.Funeral.LegacyKey}/${enq.Funeral.ID}`;
                                return (
                                    <li key={i}>
                                        <a href={enqPath} onClick={e => this.viewRecord(e, enqPath)}>
                                            Enquiry {enq.LegacyKey}
                                        </a>
                                        {!!enq.Funeral && (
                                            <Fragment>
                                                {' '}
                                                for{' '}
                                                <a href={funPath} onClick={e => this.viewRecord(e, funPath)}>
                                                    Funeral {enq.Funeral.LegacyKey}
                                                </a>
                                            </Fragment>
                                        )}
                                    </li>
                                );
                            })}
                    </ol>
                </Grid>
            </Grid>
        );
    }

    renderNotes() {
        const { form } = this.props;
        return (
            <Grid bucket>
                <Grid item xs={12}>
                    <InlineHeader header="Billing Notes" />
                    <InlineField>
                        <TextField
                            placeholder="New notes are written here..."
                            multiline={true}
                            name="BillingNotes"
                            form={form}
                        />
                    </InlineField>
                    <InlineField>
                        <HistoryField value={form.getField('OldBillingNotes')} />
                    </InlineField>
                </Grid>
            </Grid>
        );
    }

    renderLegacyDocuments() {
        const { form } = this.props;
        const legacyDocuments = form.getField('LegacyDocuments') || [];

        return (
            <Grid bucket>
                <Grid item xs={12}>
                    <Label>Legacy Documents</Label>
                </Grid>
                <Grid item xs={12}>
                    {legacyDocuments.length > 0 ? (
                        <Table>
                            <TableHeaderRow>
                                <TableHeaderCell>ID</TableHeaderCell>
                                <TableHeaderCell>Filename</TableHeaderCell>
                                <TableHeaderCell>Size</TableHeaderCell>
                            </TableHeaderRow>
                            {legacyDocuments.map((obj, i) => (
                                <TableRow key={i} pad={true}>
                                    <TableCell>#{obj.ID}</TableCell>
                                    <TableCell>
                                        <LinkButton
                                            onClick={() =>
                                                this.setState({
                                                    documentModal: { open: true, document: obj }
                                                })
                                            }
                                            text={obj.Name}
                                        />
                                    </TableCell>
                                    <TableCell>{obj.Size}</TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    ) : (
                        <i>No documents</i>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {this.renderNewUpload()}
                </Grid>
            </Grid>
        );
    }

    renderNewUpload() {
        const { form } = this.props;

        return (
            <div>
                <FileUpload
                    label={'Upload Document'}
                    buttonOnly={true}
                    folderPath={`/documents/prearrangements/${form.getField('ID')}`}
                    onComplete={({ uploadFile }) => this.onFileUploaded(uploadFile)}
                />
            </div>
        );
    }

    onFileUploaded(upload) {
        const { form } = this.props;
        const LegacyDocuments = form.getField('LegacyDocuments') || [];
        LegacyDocuments.push({
            ID: upload.id,
            AbsoluteLink: upload.AbsoluteLink,
            Name: getFileName(upload.filename),
            Size: 'Save to confirm'
        });
        form.setField({ LegacyDocuments });
    }
}

const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    }
});

export default withRouter(withStyles(styles)(Summary));
