import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import { getArray, isNullOrUndefined } from '../../../util/objects';
import { isRelatedObjectDefined } from '../../../util/bookable';
import { niceDateTimeFromString } from '../../../util/date';
import { CONTACT_CLERGY_OPTIONS } from '../../contacts/contactConstants';
import { CATERING_STAFF_REQUIRED } from '../optionals/OptionalsConstants';

export const ALLOCATION = {
    Primary: 'Primary',
    Secondary: 'Secondary'
};

export const ALLOCATION_OPTIONS = [
    { label: 'Primary', value: ALLOCATION.Primary },
    { label: 'Secondary', value: ALLOCATION.Secondary }
];

export const GenerateRunsheet = props => {
    const { form } = props;
    const context = { props };
    const runsheet = [];

    //place of service
    const placeOfService = form.getField('PlaceOfService');
    if (placeOfService) {
        const items = [];
        let confirmed = !!placeOfService.Confirmed;
        if (!!placeOfService.Type && placeOfService.Type === 'No Service No Attendance') {
            // don't need to check stuff
            confirmed = true;
        } else if (!!placeOfService.Type && placeOfService.Type === 'Graveside') {
            // defer to committal
            confirmed = !!form.getField('Disposal.Confirmed');
        } else {
            if (!!placeOfService.Location && !!placeOfService.Location.Name)
                items.push({
                    Action: 'Where',
                    Description: placeOfService.Location.Name,
                    Comment: placeOfService.Comment
                });
            else {
                items.push({
                    Action: 'Where',
                    Description: 'No venue selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });
            }

            if (!placeOfService.Date)
                items.push({
                    Description: 'Date of service has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });

            if (!placeOfService.Time)
                items.push({
                    Description: 'Time of service has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });

            if (!!placeOfService.Date && !!placeOfService.Time)
                items.push({
                    Action: 'When',
                    Description:
                        niceDateTimeFromString(placeOfService.Date + ' ' + placeOfService.Time) +
                        ` (for ${Number(placeOfService.Duration)} hours)`
                });
        }
        items.push({
            Action: 'Type',
            Description: joinDefined(
                [!!placeOfService.Type ? placeOfService.Type : null, !!placeOfService.Private ? 'Private' : null],
                ', '
            )
        });

        runsheet.push({
            Action: 'Service',
            Confirmed: !!confirmed,
            Items: items
        });
    } else {
        runsheet.push({ Action: 'Service' });
    }

    //crematorium
    const crematorium = form.getField('Disposal');

    if (!!crematorium && !!crematorium.ID && crematorium.ID !== '0') {
        const dateOfDisposal = crematorium.Date;
        const timeOfDisposal = crematorium.Time;
        const locationDisposal = crematorium.Location;
        const items = [];

        items.push({
            Action: 'Where',
            Description:
                isRelatedObjectDefined(locationDisposal) && locationDisposal.ID !== '0'
                    ? locationDisposal.Name
                    : 'No venue selected',
            Comment: crematorium.CemeterySection,
            Link: '/funeral/:key/:id/funeral-details'
        });

        if (!dateOfDisposal)
            items.push({
                Description: 'Date of disposal has not been selected',
                Link: '/funeral/:key/:id/funeral-details'
            });

        if (!timeOfDisposal)
            items.push({
                Description: 'Time of disposal has not been selected',
                Link: '/funeral/:key/:id/funeral-details'
            });

        if (!!dateOfDisposal && !!timeOfDisposal) {
            items.push({
                Action: 'When',
                Description: niceDateTimeFromString(dateOfDisposal + ' ' + timeOfDisposal) //+ ` (${crematorium.Duration} hours)`
            });
        }

        items.push({
            Action: 'Type',
            Description: joinDefined(
                [
                    crematorium.CrematedOrBuried,
                    !!crematorium.Private ? 'Private' : null,
                    !!form.getField('FollowToDisposal') ? 'Follow to Committal' : null,
                    !!crematorium.NoAttendance ? 'No Attendance' : null
                ],
                ', '
            )
        });

        runsheet.push({
            Action: 'Committal',
            Confirmed: !!crematorium.Confirmed,
            Items: items
        });
    } else {
        runsheet.push({ Action: 'Committal' });
    }

    //celebrant/clergy
    const clergyBookings = form.getField('Clergy');
    if (clergyBookings && clergyBookings.length > 0) {
        const items = [];

        clergyBookings.forEach(clergy => {
            const clergyCategory =
                !isNullOrUndefined(clergy.Contact) && !stringIsNullOrEmpty(clergy.Contact.ClergyCategory)
                    ? CONTACT_CLERGY_OPTIONS.find(e => e.value === clergy.Contact.ClergyCategory)
                    : null;

            const clergyCategoryLabel = !isNullOrUndefined(clergyCategory) ? clergyCategory.label : null;

            items.push({
                Action: 'Name',
                Description: (clergy.Contact && `${clergy.Contact.Name}`) || '(no celebrant)',
                Confirmed: clergy.Confirmed,
                Comment: joinDefined([clergyCategoryLabel, clergy.Comment], ', ')
            });
        });
        runsheet.push({
            Action: 'Celebrant',
            Items: items
        });
    } else {
        // runsheet.push({ Action: 'Celebrant' });
    }

    /*
        //catering staff
        runsheet.push(
            createRunSheetGroup(
                context,
                'Catering Staff',
                [{ name: 'CateringStaffs' }],
                null,
                cs => cs.StaffID,
                cs => cs.UnavailableComment
            )
        );
    */

    //place of viewing
    const placesOfViewing = !!form.getField('PlaceOfViewingRequired') && form.getField('PlaceOfViewingItems');
    if (placesOfViewing && placesOfViewing.length) {
        placesOfViewing.forEach((placeOfViewing, index, array) => {
            const items = [];
            if (!placeOfViewing.Date)
                items.push({
                    Description: 'Date of viewing has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });

            if (!placeOfViewing.Time)
                items.push({
                    Description: 'Time of viewing has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });

            if (!placeOfViewing.Duration)
                items.push({
                    Description: 'Duration of viewing has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });

            if (!placeOfViewing.Location)
                items.push({
                    Description: 'Location of viewing has not been selected',
                    Link: '/funeral/:key/:id/funeral-details'
                });
            else
                items.push({
                    Action: 'Where',
                    Description: placeOfViewing.Location.Name,
                    Comment: placeOfViewing.Comment
                });

            if (!!placeOfViewing.Date && !!placeOfViewing.Time && !!placeOfViewing.Duration)
                items.push({
                    Action: 'When',
                    Description:
                        niceDateTimeFromString(placeOfViewing.Date + ' ' + placeOfViewing.Time) +
                        ` (for ${Number(placeOfViewing.Duration)} hours)`
                });

            if (!!placeOfViewing.Type || !!placeOfViewing.ViewingPrivate || !!placeOfViewing.TypeOfViewingAdv)
                items.push({
                    Action: 'Type',
                    Description: joinDefined(
                        [
                            !!placeOfViewing.Type ? placeOfViewing.Type : null,
                            !!placeOfViewing.ViewingPrivate ? 'Private' : null,
                            !!placeOfViewing.TypeOfViewingAdv ? 'Advertise' : null
                        ],
                        ', '
                    )
                });

            runsheet.push({
                Action: 'Viewing' + (array.length > 1 ? ' #' + (1 + index) : ''),
                Confirmed: !!placeOfViewing.Confirmed,
                Items: items
            });
        });
    } else {
        // runsheet.push({ Action: 'Viewing' });
    }

    //cars
    runsheet.push(
        createRunSheetGroup(
            context,
            'Mourning Cars',
            [
                { name: 'LimoBookings', label: 'Hired Car or Limousine' },
                { name: 'Cars', label: 'Company Car' }
            ],
            vehicle => {
                return (
                    niceDateTimeFromString(
                        (vehicle.Date || vehicle.DeliveryDate) + ' ' + (vehicle.Time || vehicle.DeliveryTime)
                    ) + ` (for ${Number(vehicle.Duration)} hours)`
                );
            },
            null,
            vehicle => {
                return vehicle.Address || vehicle.DeliveryAddress;
            }
        )
    );

    //hearse
    const HearseRequired = form.getField('HearseRequired');
    if (HearseRequired) {
        const items = [];
        const HearseToPassHouse = form.getField('HearseToPassHouse');
        if (!!HearseToPassHouse) {
            items.push({
                Action: 'Hearse to Pass House',
                Description: niceDateTimeFromString(
                    form.getField('HearseToPassHouseDate') + ' ' + form.getField('HearseToPassHouseTime')
                )
            });
        }
        const HearseToPassHouseAfterService = form.getField('HearseToPassHouseAfterService');
        if (!!HearseToPassHouseAfterService) {
            items.push({
                Action: 'Hearse to Pass House After Service',
                Description: niceDateTimeFromString(
                    form.getField('HearseToPassHouseAfterServiceDate') +
                        ' ' +
                        form.getField('HearseToPassHouseAfterServiceTime')
                )
            });
        }
        const HearseDriveAway = form.getField('HearseDriveAway');
        if (!!HearseDriveAway) {
            items.push({
                Action: 'Drive Away',
                Description: niceDateTimeFromString(
                    form.getField('HearseDriveAwayDate') + ' ' + form.getField('HearseDriveAwayDate')
                )
            });
        }
        const HearseComment = form.getField('HearseComment');
        if (!!HearseComment) {
            items.push({ Action: 'Comment', Description: form.getField('HearseComment') });
        }

        runsheet.push({
            Action: 'Hearse',
            Confirmed: undefined,
            Items: items
        });
    }

    //musicians
    runsheet.push(
        createRunSheetGroup(context, 'Musicians', [
            { name: 'ReflectionMusicians', label: 'Reflection Musician' },
            { name: 'GravesideMusicians', label: 'Graveside Musician' },
            { name: 'DefaultMusicians', label: 'Default Musician' },
            { name: 'EnterMusicians', label: 'Enter Musician' },
            { name: 'ExitMusicians', label: 'Exit Musician' }
        ])
    );

    // other bookings
    [
        { key: 'RslMasonic', supplierLabel: 'Name', label: 'RSL/Masonic Representatives', link: 'presentation' },
        { key: 'Florists', supplierLabel: 'Florist', label: 'Flowers', link: 'optionals' },
        { key: 'PersonalisedTouches', label: 'Personalised Touches', link: 'optionals' },
        { key: 'MemorialStationary', label: 'Memorial Stationery', link: 'optionals' },
        { key: 'GraveLedger', label: 'Grave Ledger', link: 'funeral-details' },
        { key: 'Caterers', supplierLabel: 'Refreshments Supplier', label: 'Refreshments', link: 'optionals' }
    ].forEach(supplier => {
        runsheet.push(
            createRunSheetGroup(
                context,
                supplier.label,
                [{ name: supplier.key, label: supplier.supplierLabel || 'Supplier' }],
                null,
                null,
                order => {
                    return (
                        ((!!order.BookingItems &&
                            order.BookingItems.map((item, i, a) => {
                                return (
                                    ' ' +
                                    item.Quantity +
                                    'x ' +
                                    (item.Title || (!!item.Product ? item.Product.Title : '(unknown)')) +
                                    (1 + i === a.length ? '. ' : '')
                                );
                            })) ||
                            '') + (order.Comment || '')
                    );
                },
                null,
                supplier.link
            )
        );
    });

    //webcast
    const { WebcastRequired, WebcastConfirmed, WebcastEmailedLinkToFamily, WebcastComment } = context.props.form;
    if (WebcastRequired) {
        runsheet.push({
            Action: 'Webcast',
            Confirmed: WebcastConfirmed || false,
            Items: [
                {
                    Action: 'Link Emailed',
                    Description: WebcastEmailedLinkToFamily,
                    Comment: WebcastComment
                }
            ]
        });
    } else {
        // runsheet.push({ Action: 'Webcast' });
    }

    //valuables
    const valuables = getArray(context, 'ValuableItems') || [];
    if (valuables.length > 0) {
        const items = [];
        for (let x = 0; x < valuables.length; x++) {
            items.push({
                Action: `${valuables[x].Quantity} x ${valuables[x].Description}`,
                Description: valuables[x].ToBeReturned
                    ? 'To be returned' + (valuables[x].WhereToBeReturned ? ` to ${valuables[x].WhereToBeReturned}` : '')
                    : null,
                Comment: valuables[x].Note,
                Confirmed: valuables[x].Signed
            });
        }
        runsheet.push({ Action: 'Valuables', Items: items });
    } else {
        // runsheet.push({ Action: 'Valuables' });
    }

    //refreshments venue
    const refreshmentsVenue = form.getField('RefreshmentsVenue');
    if (refreshmentsVenue && (refreshmentsVenue.Type === 'Venue' || refreshmentsVenue.Type === 'Offsite')) {
        const items = [];

        if (refreshmentsVenue.Type === 'Venue') {
            items.push({
                Action: 'Where',
                Description: refreshmentsVenue.Location
                    ? joinDefined(
                          [
                              refreshmentsVenue.Location.AddressLine1,
                              refreshmentsVenue.Location.AddressLine2,
                              refreshmentsVenue.Location.Suburb,
                              refreshmentsVenue.Location.Postcode,
                              refreshmentsVenue.Location.State
                          ],
                          ', '
                      )
                    : 'No venue selected',
                Comment: refreshmentsVenue.Comment,
                Link: '/funeral/:key/:id/optionals'
            });
        } else if (refreshmentsVenue.Type === 'Offsite') {
            items.push({
                Action: 'Where',
                Description: joinDefined(
                    [
                        refreshmentsVenue.OffsiteAddressLine1,
                        refreshmentsVenue.OffsiteSuburb,
                        refreshmentsVenue.OffsitePostcode,
                        refreshmentsVenue.OffsiteState
                    ],
                    ', '
                ),
                Comment: refreshmentsVenue.Comment,
                Link: '/funeral/:key/:id/optionals'
            });
            // } else {
            //     throw new Error('unknown venue type ' + refreshmentsVenue.Type);
        }

        items.push({
            Action: 'When',
            Description:
                (niceDateTimeFromString(refreshmentsVenue.Date + ' ' + refreshmentsVenue.Time) || 'Not specified') +
                ` (for ${Number(refreshmentsVenue.Duration)} hours)`
        });

        items.push({
            Action: 'Number of People',
            Description: Number(refreshmentsVenue.NumberOfPeople) > 0 ? refreshmentsVenue.NumberOfPeople : 'Not set'
        });

        const catering = refreshmentsVenue.CateringStaffAllocations || [];
        if (catering.length > 0) {
            const numberCaterersNeeded = CATERING_STAFF_REQUIRED(refreshmentsVenue.NumberOfPeople || 0);
            const staffConfirmed = catering.filter(e => !!e.Confirmed && !!e.Availability && !!e.CateringStaff);
            const staffAvailable = catering.filter(e => !!e.Availability && !!e.CateringStaff);
            items.push({
                Action: 'Catering Staff',
                Description:
                    numberCaterersNeeded +
                    ' required - ' +
                    joinDefined(
                        staffAvailable.map(e => e.CateringStaff.Name),
                        ', '
                    ),
                Confirmed:
                    staffConfirmed.length === numberCaterersNeeded && staffAvailable.length === staffConfirmed.length
            });
        }

        runsheet.push({
            Action: 'Refreshments Venue',
            Confirmed: !!refreshmentsVenue.Confirmed,
            Items: items
        });
    }

    //specials instructions
    runsheet.push(
        createRunSheetGroup(
            context,
            'Special Instructions',
            [{ name: 'SpecialInstructions' }],
            si => si.Action,
            si => si.Check || false
        )
    );

    return runsheet;
};

const createRunSheetGroup = (context, runsheetGroupName, arrayNames, formatFunc, confirmedFunc, commentFunc, link) => {
    const items = [];
    for (let x = 0; x < arrayNames.length; x++) {
        const formArray = getArray(context, arrayNames[x].name);
        for (let y = 0; y < formArray.length; y++) {
            if (!!formArray[y].Cancelled) continue;
            items.push({
                Action: arrayNames[x].label,
                Description: formatFunc
                    ? formatFunc(formArray[y])
                    : !!formArray[y].Contact
                    ? formArray[y].Contact.Name
                    : 'No supplier selected',
                Confirmed: confirmedFunc ? confirmedFunc(formArray[y]) : !!formArray[y].Confirmed || false,
                Comment: commentFunc ? commentFunc(formArray[y]) : formArray[y].Comment,
                Link: link && !formArray[y].Confirmed && !formArray[y].Contact ? link : undefined
            });
        }
    }
    return items.length > 0
        ? {
              Action: runsheetGroupName,
              Confirmed: undefined,
              Items: items
          }
        : {};
};
