import React, { Component } from 'react';
import Grid from '../form/Grid';
import { ImageList, ImageListItem } from '../form/ImageList';
import { ProductConfig } from '../../util/products';
import PrimaryButton from '../form/PrimaryButton';
import ProductSelectionModal from '../product-modal/ProductSelectionModal';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, Typography } from '@material-ui/core';
import { isNullOrUndefined } from 'util';

class SupplierItems extends Component {
    state = {
        modalOpen: false
    };

    render() {
        const { name, index, form, productsModalTabs, classes, label = 'Products', itemStyle = 'panel', disabled } = this.props;
        const { modalOpen } = this.state;
        const confirmed = form.getField(`${name}[${index}].Confirmed`);
        const cancelled = form.getField(`${name}[${index}].Cancelled`);

        return (
            <div>
                {!confirmed && productsModalTabs && productsModalTabs.length > 0 && (
                    <ProductSelectionModal
                        onClose={() => this.setState({ modalOpen: false })}
                        open={modalOpen}
                        onConfirm={bookingItems => {
                            const newState = form.getField(name);
                            newState[index].BookingItems = bookingItems;
                            form.setField({ [name]: newState });
                        }}
                        form={form}
                        formCartProperty={`${name}[${index}].BookingItems`}
                        tabs={productsModalTabs}
                    />
                )}
                {this.renderCards(itemStyle)}
                {!confirmed && !cancelled && (
                    <Grid item xs={12} className={classes.chooseGrid}>
                        <PrimaryButton disabled={disabled} onClick={e => this.setState({ modalOpen: true })}>
                            {`Choose ${label}...`}
                        </PrimaryButton>
                    </Grid>
                )}
            </div>
        );
    }

    renderCards(itemStyle) {
        const { classes, form, name, index } = this.props;
        const rawItems = form.getField(`${name}[${index}].BookingItems`) || [];
        const bookingItems = rawItems.edges ? rawItems.edges.map(e => e.node) : rawItems.map(e => e);
        if (bookingItems.length === 0) return <p className={classes.noFriends}>No products selected.</p>;

        if (itemStyle === 'card') {
            return (
                <div className={classes.flowerList}>
                    {bookingItems.map(({ ID, ProductID, VariationID, Quantity, Title }, i) => {
                        const product = ProductConfig.productMap[ProductID];
                        if (!product) return null;
                        const titleObj = product.variations.find(e => e.id === VariationID) || product;
                        return (
                            <Card key={ID || i} className={classes.flowerItem} elevation={0}>
                                <div
                                    style={{ backgroundImage: `url(${product.image}` }}
                                    className={classes.flowerItemImage}
                                />
                                <Typography className={classes.flowerItemName} align="center">
                                    {Quantity > 1 && <span className={classes.flowerItemQty}>x{Quantity}</span>}{' '}
                                    {Title || titleObj.title}
                                </Typography>
                            </Card>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className={classes.imageListContainer}>
                    <ImageList>
                        {bookingItems.map((value, i) => {
                            const product = ProductConfig.productMap[value.ProductID];
                            return isNullOrUndefined(product) ? null : (
                                <ImageListItem
                                    key={i}
                                    value={product}
                                    quantity={value.Quantity}
                                    title={value.Title || product.title}
                                />
                            );
                        })}
                    </ImageList>
                </div>
            );
        }
    }
}

const styles = ({ palette, typography }) => ({
    chooseGrid: {
        paddingBottom: '16'
    },
    imageListContainer: {
        padding: '0 0 8px'
    },
    flowerList: {
        display: 'flex',
        flexWrap: 'wrap',
        // paddingBottom: 8,
        margin: '0 -8px'
    },
    flowerItem: {
        flex: '0 0 auto',
        display: 'flex',
        padding: '16px 16px 12px',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '8px',
        border: `1px solid ${palette.divider}`,
        width: '200px',
        position: 'relative'
    },

    flowerItemImage: {
        width: '100%',
        height: 128,
        border: `1px solid ${palette.divider}`,
        marginBottom: 12,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    flowerItemName: {
        // fontSize: typography.caption.fontSize
    },
    flowerItemQty: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: `${palette.divider}`,
        borderRadius: '5px 0px 0px 0px',
        padding: '2px 5px',
        fontWeight: 'bold'
    },
    noFriends: {
        fontSize: '0.875rem',
        fontStyle: 'italic',
        color: '#999'
    }
});

export default withStyles(styles)(SupplierItems);
