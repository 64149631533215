import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import cx from 'classnames';
import ExclamationCircleIcon from './icon/ExclamationCircleIcon';
import TickCircleIcon from './icon/TickCircleIcon';
import InfoCircleIcon from './icon/InfoCircleIcon';
import ExclamationTriangleIcon from './icon/ExclamationTriangleIcon';
import Spinner from './Spinner';
import FireIcon from './icon/FireIcon';

const BiggerMessageBar = ({ messageType, classes, children }) => {
    const types = ['good', 'error', 'warning'];
    types['good'] = { icon: <TickCircleIcon />, class: 'happiness' };
    types['error'] = { icon: <ExclamationCircleIcon />, class: 'sadness' };
    types['warning'] = { icon: <ExclamationTriangleIcon />, class: 'worriedness' };
    types['fire'] = { icon: <FireIcon />, class: 'worriedness' };
    types['loading'] = { icon: <Spinner width={'24px'} />, class: 'curiousness' };
    types['info'] = { icon: <InfoCircleIcon />, class: 'curiousness' };

    if (!types[messageType]) {
        messageType = 'info';
    }

    return (
        <div className={cx(classes.message, classes[types[messageType].class])}>
            {types[messageType].icon}
            <div>{children}</div>
        </div>
    );
};

const styles = () => ({
    message: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #35327c',
        backgroundColor: '#eff8fd',
        borderRadius: '3px',
        fontSize: '1rem',
        padding: '12px 15px',
        margin: '6px 0',
        width: '100%',
        '& > svg': {
            color: '#35327c'
        },
        '& > :not(:first-child)': {
            flexGrow: '1',
            marginLeft: '12px'
        },
        minHeight: 40
    },
    happiness: {
        borderColor: '#54be82',
        background: '#e9faf0',
        '& > svg': {
            color: '#54be82'
        }
    },
    sadness: {
        borderColor: '#ea5d5d',
        background: '#fdebeb',
        '& > svg': {
            color: '#ea5d5d'
        }
    },
    worriedness: {
        borderColor: '#eab35d',
        background: '#fdfceb',
        '& > svg': {
            color: '#eab35d'
        }
    },
    curiousness: {
        borderColor: '#413bbe',
        background: '#f7eefb',
        '& > svg': {
            color: '#413bbe'
        }
    }
});

export default withStyles(styles)(BiggerMessageBar);
