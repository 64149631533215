import Family from './Family';
import FamilyFragment from './FamilyFragment';
import { flattenConnection } from '../../../util/functions';
import { isNullOrUndefined } from '../../../util/objects';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { joinValidationResults, messages, validationHelper } from '../../../util/validation';
import { ChildFieldVerboseNames, MarriageFieldVerboseNames } from './FamilyConstants';

export default {
    label: 'Family',
    component: Family,
    fragment: FamilyFragment,
    onLoad: data => {
        flattenConnection(data, 'ChildrenDetails');
        flattenConnection(data, 'Marriages');
        data.FamilyChangesFromBDM = JSON.parse(data.FamilyChangesFromBDM || "{}");
    },

    formatSaveData: (saveData, state) => {
        if (!!saveData.Marriages && saveData.Marriages.length > 0) {
            state.Marriages.forEach((marriage, index) => {
                if (index === 0) {
                    if (marriage.Type === 'Unknown') {
                        saveData.Marriages = [];
                    } else if (marriage.Type === 'Never married') {
                        saveData.Marriages = [{ Type: marriage.Type }];
                    }
                }
            });
        }
        if (!!saveData.Marriages && saveData.Marriages.length > 0) {
            saveData.Marriages.forEach(marriage => {
                if (isNaN(marriage.AgeMarried) || 0.1 > Number(marriage.AgeMarried)) marriage.AgeMarried = null;
                else marriage.AgeMarried = Number(marriage.AgeMarried);
            });
        }
        saveData.FamilyChangesFromBDM =
            Object.keys(saveData.FamilyChangesFromBDM || {}).length === 0
                ? null
                : JSON.stringify(saveData.FamilyChangesFromBDM);
    },

    validation: {
        onValidate: {
            Marriages: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM');
                const existingMarriages = [];
                const validationResults = [];
                (persistedValue || []).some((marriage, index) => {
                    const invalidFields = [];
                    const suggestedFields = [];
                    existingMarriages.push(marriage.ID);

                    //if this is the current marriage, we only validate for 'Married' status. others don't matter
                    if (index === 0) {
                        if (marriage.Type !== 'Married') {
                            return true;
                        }

                        if (stringIsNullOrEmpty(marriage.FirstName)) {
                            invalidFields.push(`Marriages[${index}].FirstName`);
                        }

                        if (Number(marriage.AgeMarried) < 0) {
                            invalidFields.push(`Marriages[${index}].AgeMarried`);
                        }

                        if (stringIsNullOrEmpty(marriage.Suburb)) {
                            suggestedFields.push(`Marriages[${index}].Suburb`);
                        }

                        if (invalidFields.length > 0)
                            validationResults.push(validationHelper.required(messages.required, invalidFields));

                        if (suggestedFields.length > 0)
                            validationResults.push(validationHelper.suggested(messages.suggested, suggestedFields));

                        return false;
                    }
                    return false;
                });

                if (changes.Marriages !== undefined) {
                    const newMarriages = [];
                    changes.Marriages.forEach(marriage => {
                        if (existingMarriages.indexOf(marriage.ID) === -1) {
                            const fields = [];
                            MarriageFieldVerboseNames.forEach(element => {
                                fields.push(`${element.Name}: ${marriage[element.Field]}`);
                            });
                            newMarriages.push(fields.join('; '));
                        }
                    });
                    if (newMarriages.length > 0) {
                        return validationHelper.optional('Marriages were added: ' + newMarriages.join(' // '));
                    }
                }

                return joinValidationResults(validationResults);
            },

            ChildrenDetails: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM');
                const existingChildren = [];

                const validationResults = [];
                (persistedValue || []).forEach((child, index) => {
                    const invalidFields = [];
                    existingChildren.push(child.ID);

                    if (stringIsNullOrEmpty(child.GivenName)) {
                        invalidFields.push(`ChildrenDetails[${index}].GivenName`);
                    }

                    //either dob or age is required. if dob is null, the Age field will be showing. so show the validation error on that
                    if (isNullOrUndefined(child.DateOfBirth) && Number(child.Age) < 0) {
                        invalidFields.push(`ChildrenDetails[${index}].Age`);
                    }

                    if (stringIsNullOrEmpty(child.Gender)) {
                        invalidFields.push(`ChildrenDetails[${index}].Gender`);
                    }

                    if (invalidFields.length > 0)
                        validationResults.push(validationHelper.required(messages.required, invalidFields));
                });

                if (changes.ChildrenDetails !== undefined) {
                    const newChildren = [];
                    changes.ChildrenDetails.forEach(child => {
                        if (existingChildren.indexOf(child.ID) === -1) {
                            const fields = [];
                            ChildFieldVerboseNames.forEach(element => {
                                fields.push(`${element.Name}: ${child[element.Field]}`);
                            });
                            newChildren.push(fields.join('; '));
                        }
                    });
                    if (newChildren.length > 0) {
                        return validationHelper.optional('Children were added: ' + newChildren.join(' // '));
                    }
                }

                return joinValidationResults(validationResults);
            },
            'Parent1.Type': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Type';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent1.FirstName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.FirstName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent1.MiddleName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.MiddleName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent1.SurnameAtBirth': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.SurnameAtBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent1.Surname': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Surname';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent1.Occupation': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Occupation';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.Type': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Type';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.FirstName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.FirstName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.MiddleName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.MiddleName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.SurnameAtBirth': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.SurnameAtBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.Surname': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Surname';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            },
            'Parent2.Occupation': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Occupation';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Value has changed, new value: ' + changes[fieldName]);
                }
                return validationHelper.ok();
            }
        }
    }
};
