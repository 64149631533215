import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import history from '../../History';
import { joinDefined } from '../../util/strings';

/**
 * uses an anchor to display text and behaves like a button
 */
const LinkButton = ({ title, classes, className, text, onClick, disabled, href, target, children }) => {
    const colourClass = disabled || (!onClick && !href) ? classes.disabled : classes.enabled;

    const onClickEvent = e => {
        e.stopPropagation();
        if (href) {
            e.preventDefault();
        }
        if (onClick && typeof onClick === 'function') return onClick(e);
        else if (href && target) return window.open(href, target);
        else if (href) return history.push(href);
    };

    if (target) title = joinDefined([title, '(Opens in a new window)'], ' ');

    return (
        <a
            title={title}
            className={cx(classes.root, colourClass, className, !disabled && target && href && 'newpage')}
            onClick={!!disabled ? undefined : onClickEvent}
            href={href}
        >
            {children}
            {!!text && <span>{text}</span>}
        </a>
    );
};

const styles = ({ palette, breakpoints, funeralHome }) => ({
    root: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '& > span': {
            display: 'inline-block',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            padding: '0',
            font: 'inherit',
            lineHeight: '16px;'
        }
    },
    enabled: {
        '& > span': {
            color: palette.basePrimary[funeralHome],
            textDecoration: 'underline',
            '&:hover': {
                color: palette.baseSecondary[funeralHome]
            }
        },
        '&:active > span': {
            color: palette.contentForeground[funeralHome],
            '&:hover': {
                color: palette.baseSecondary[funeralHome]
            }
        }
    },
    disabled: {
        cursor: 'default',
        color: '#AAAAAA',
        '& > span': {
            color: '#AAAAAA',
            borderColor: '#AAAAAA'
        }
    }
});

export default withStyles(styles)(LinkButton);
